import React from "react";
import { Button } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";

export const LogInStatus = ({user}: any) => {
  return (
    <div data-testid="hyd-login-status">
      {user.loggedIn ? (
        <FormattedMessage
          id="login-status.logged-in"
          defaultMessage="Logged in"
          description="label for login"
        />
      ) : (
        <Button
          data-testid="hyd-login-button"
          variant="ghost"
          id="login"
          as="a"
          href={user?.loginUrl}
        >
          <FormattedMessage
            id="login-status.go-to-login"
            defaultMessage="Login for prices"
            description="label for login link"
          />
        </Button>
      )}
    </div>
  );
};
