import React from "react";
import { StockIndicator } from "@eriksdigital/atomic-ui/components";
import { DeliveryDate } from "./style";
import {FormattedMessage} from "react-intl";
import { Statuses, StockLevel } from '../../types/index';

const statuses = {
  CompletelyOnStock: {
    status: "inStock",
    message:  <FormattedMessage
        id="stock-info.CompletelyOnStock"
        defaultMessage="In stock"
        description="Label of Not price in search results section"
    />,
  },
  NothingOnStock: {
    status: "outOfStock",
    message:  <FormattedMessage
        id="stock-info.NothingOnStock"
        defaultMessage="Out of stock"
        description="Label of out of stock in search results section"
    />,
  },
  Loading: {
    status: "loading",
    message: <FormattedMessage
        id="stock-info.loading"
        defaultMessage="Loading stock information"
        description="Label of loading in search results section"
    />,
  },
  Unavailable: {
    status: "infoUnavailable",
    message:  <FormattedMessage
        id="stock-info.unavailable"
        defaultMessage="Stock information unavailable"
        description="Label of loading in search results section"
    />,
  },
};

export const StockInformation = ({ stockStatus }: {
    stockStatus : StockLevel,
  }) => {

  //if the value of stockstatus.available is null then changing the value to unavailable to avoid error.
  const status: Statuses = stockStatus?.available === null ? Statuses.Unavailable : stockStatus?.available;
  return (
    <>
      { stockStatus && stockStatus.available === "NothingOnStock" &&
        (<DeliveryDate>
          <FormattedMessage
              id="stock-info.deliveryDate"
              defaultMessage="Estimated delivery date"
              description="Label of estimated delivery date"
          /> {stockStatus?.deliveryDate}
        </DeliveryDate>)
      }
      {stockStatus && <StockIndicator
        quantity={stockStatus?.quantity}
        status={statuses[status]?.status}
        stockMessage={statuses[status]?.message}
      />}
    </>
  );
};
