import React, { useContext } from "react";
import { Box } from "@eriksdigital/atomic-ui/components";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { Result, ItemImage, Info, ItemDescription } from "./styles";
import { StockInformation } from "../StockInformation";
import { Price } from "../Price";
import { FormattedMessage } from "react-intl";
import { ItemData, StockLevel } from "../../types/index";

export interface ResultItemProps {
  index: number;
  item: ItemData;
  stockData: StockLevel;
}

const ResultItem = ({ index, item, stockData }: ResultItemProps) => {
  //@ts-ignore
  const { sendSelectItemEvent } = useContext<any>(GTMContext);
  const {
    description,
    manufacturers,
    erpPartNumber,
    brand,
    medias,
    itemLink,
    englishDescription,
    partNumber,
    currency,
    productId,
    priceRaw,
    price,
  } = item;

  const onClickItem = () => {
    sendSelectItemEvent({
      itemId: partNumber,
      price: priceRaw,
      itemBrand: brand,
      itemStockStatus: stockData?.available,
      itemName: englishDescription,
      index: index + 1,
      materialNumber: erpPartNumber,
    });
    window.open(itemLink, "_blank");
  };
  // needed temporary while qa env is not fixed, currently it returns null
  const hasManufacturers = manufacturers && manufacturers[0];

  return (
    <Result data-testid={`result-item-${index}`}>
      <ItemImage
        src={medias[0]?.fullImage || ""}
        alt={`result-item-image-${index}`}
        data-testid={`result-item-image-${index}`}
      />

      <Info>
        <ItemDescription
          data-testid={`description-${index}`}
          data-hj-whitelist
          onClick={onClickItem}
          role="link"
        >
          <p>{brand}</p> {description}
        </ItemDescription>
        <span data-testid={`sku-${index}`} data-hj-whitelist>
          <FormattedMessage
            id="hydrofil.internal-product-identifier"
            defaultMessage="Unable to fetch prices"
            description="Label for fetch prices error"
          />
          : {erpPartNumber}
        </span>
        <span data-testid={`manufacturer-${index}`} data-hj-whitelist>
          <FormattedMessage
            id="hydrofil.manufacturer"
            defaultMessage="Manufacturer"
            description="Label for manufacture field"
          />
          : {hasManufacturers && manufacturers?.[0]?.manufacturer}
        </span>
        <span data-testid={`manufacturerPartNumber-${index}`} data-hj-whitelist>
          <FormattedMessage
            id="hydrofil.external-product-identifier"
            defaultMessage="Manufacturer’s part number"
            description="Label for external product identifier"
          />
          : {hasManufacturers && manufacturers?.[0]?.articleNumber}
        </span>
        <StockInformation stockStatus={stockData} />
      </Info>
      <Box
        display="flex"
        direction="row"
        alignItems="center"
        flex="grow"
        justifyContent="end"
      >
        <Price
          erpPartNumber={erpPartNumber}
          partNumber={partNumber}
          englishDescription={englishDescription}
          brand={brand}
          itemLink={itemLink}
          currency={currency}
          position={index + 1}
          productId={productId}
          stockStatus={stockData?.available}
          priceRaw={priceRaw}
          price={price}
        />
      </Box>
    </Result>
  );
};

export default ResultItem;
