export enum Statuses {
  CompletelyOnStock = "CompletelyOnStock",
  NothingOnStock = "NothingOnStock",
  Loading = "Loading",
  Unavailable = "Unavailable",
}

export interface Media {
  fullImage: string;
  mediaType: string;
}

export interface StockLevel {
  available: Statuses;
  deliveryDate: string;
  number: string;
  quantity: number;
  unit: string;
}

export interface User {
  customerNumber: string | null;
  isEditMode: boolean;
  isGuestUser: boolean;
  loggedIn: boolean;
  loginUrl: string;
  organizationName: string | null;
  userId: string | null;
  userSalesChannel: string | null;
  userSegment: string | null;
  userType: string | null;
}

export interface SalesOrganization {
  country: string;
  guided_selling_base_url: string;
  sales_organization: string;
  shop_url: string;
  store_id: number;
}

export interface AttributesListType {
  value: string;
}

export interface Attributes {
  manufacturer: Array<AttributesListType>;
}

export interface AttributeType {
  featureName: string;
  value: string;
}

export interface ItemData {
  attributes?: AttributeType[];
  brand?: string;
  currency?: string;
  description?: string;
  englishDescription?: string;
  erpPartNumber: string;
  itemLink?: string;
  manufacturers?: Array<ManufacturersType>;
  medias: Media[];
  nominalQuantity?: string;
  partNumber: string;
  price?: string;
  priceRaw?: number;
  productId?: string;
  sellable?: string;
}

export interface ManufacturersType {
  articleNumber: string;
  manufacturer: string;
}

export interface Languages {
  countryCode: string;
  countryName: string;
}

interface OrderItem {
  x_productImage: string;
  x_erpPartNumber: string;
  x_itemPrice: string;
  x_itemNetValue: string;
  x_itemStockAvailable: string;
  partNumber: string;
  x_productBrand: string;
  x_productName: string;
  quantity: string;
  x_quantityMult: string;
}

export interface ShoppingCart {
  x_grandTotal: string;
  x_totalOrderItemCount: number;
  orderItem: Array<OrderItem>;
}

export interface PriceType {
  erpPartNumber: string;
  partNumber: string;
  englishDescription?: string;
  brand?: string;
  itemLink?: string;
  currency?: string;
  position?: number;
  productId?: string;
  stockStatus?: string;
  price?: string;
  priceRaw?: number;
  pricesData?: any;
}

export interface OptionType {
  value: string;
  label: string;
}

export interface AddToCartProps extends PriceType {
  addToCartLoading: boolean;
}

export interface PriceResponse {
  price: string;
  priceRaw: number | null;
  currency: string | null;
  number: string;
}
