import styled from "styled-components";
import { Button as unStyledButton } from "@eriksdigital/atomic-ui/components";

export const Aside = styled.aside`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-top: 35px;
  width: 100%;
`;

export const FieldGroup = styled.fieldset`
  padding: 15px;

  > div + div {
    margin-top: 20px;
  }
`;

export const Button = styled(unStyledButton)`
  max-width: 174px;
`;
