import styled from "styled-components";
import { P as unStyledP } from "@eriksdigital/atomic-ui/components";

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 20px;
  }

  h3 + p {
    margin-bottom: 12px;
  }
`;

export const P = styled(unStyledP)`
  font-size: 14px;
`;
