import React from "react";

import ResultItem from "../ResultItem";
import Error from "../Error";
import NoResults from "../NoResults";

import { Wrapper } from "./styles";
import {useIntl} from "react-intl";

const Results = ({ loading, error, products, stockdata }: any) => {
  const intl = useIntl();

  if (error)
    return (
      <Error
        data-testid="results-error"
        style={{ height: "100%" }}
        after={intl.formatMessage({
          id: "no-results.could-not-load",
          defaultMessage: "Results could not be loaded",
          description: "label for error results not loaded.",
        })}
      />
    );
  if (!loading && !products?.length) return <NoResults />;

  return (
    <Wrapper data-testid="results">
      {products && products.map((product: any, index: number) => (
        <ResultItem
          key={`product-${index}-${product.partNumber}`}
          index={index}
          item={product}
          stockData={stockdata?.find((itemStock: any) => itemStock.number === product.erpPartNumber)}
        />
      ))}
    </Wrapper>
  );
};

export default Results;
