import React from "react";
import {
  NotificationProvider,
} from "@eriksdigital/atomic-ui/components";
import {
  defaultTheme,
} from "@eriksdigital/atomic-ui/styles";

import Header from "./components/Header";
import Hydrofil from "./containers/Hydrofil";

import { ThemeProvider } from "styled-components";
import { NotificationCenter } from "./components/NotificationCenter";
import {TranslationProvider} from "./providers/TranslationProvider";
import { ResourcesProvider } from "@eriksdigital/raffaella";
import { config } from "./resourcesConfig";
import apiPath from "./utils/apiPath";

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ResourcesProvider config={config} apiPath={apiPath()}>
        <TranslationProvider>
            <NotificationProvider>
              <Header />
              <Hydrofil />
              <NotificationCenter />
            </NotificationProvider>
        </TranslationProvider>
      </ResourcesProvider>
    </ThemeProvider>
  );
};

export default App;
