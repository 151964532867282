import React, {SyntheticEvent} from "react";
import { MenuButton, HamBar } from "./styles";

interface HamburgerProps {
  onClick?(e: SyntheticEvent): void;
  id?: string;
  open?: boolean;
  color?: string;
}

const Hamburger : React.FC<HamburgerProps> = (props) => {
  const { onClick, id, open = false, color = "#0163af" } = props;

  return (
    <MenuButton id={id} data-testid="menu-button" onClick={onClick}>
      <HamBar open={open} color={color} />
      <HamBar open={open} color={color} />
      <HamBar open={open} color={color} />
    </MenuButton>
  );
};

Hamburger.displayName = "Hamburger";

export default Hamburger;
