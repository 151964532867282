import styled from "styled-components";

export const Main = styled.main`
  display: flex;
  margin: 40px auto 0;
  max-width: 1124px;
  padding: 0 25px;

  > div {
    max-width: 20%;
  }
`;

export const ResultActions = styled.div`
  display: flex;
  margin-top: 40px;

  div + div {
    margin-left: 10px;
  }

  div + div + div {
    margin: auto 0 0 auto;
  }
`;

export const BottomActions = styled(ResultActions)`
  margin-bottom: 40px;

  > div {
    margin-left: auto;
  }
`;
