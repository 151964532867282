import React from "react";
import { Box, LoadingIndicator } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";
import { PriceResponse } from "../../types";

export interface PriceDetails {
  isLoading: boolean;
  error: string | null;
  data: Array<PriceResponse> | null;
}

export const PriceInformation = ({
  price,
  priceDetails,
}: {
  price?: string;
  priceDetails: PriceDetails;
}) => {
  if (!priceDetails || priceDetails?.isLoading) {
    return (
      <Box display="inlineBlock" marginRight="sp16" data-testid="price-info">
        <LoadingIndicator size="sz16" />
      </Box>
    );
  }
  if (priceDetails?.error) {
    return (
      <Box display="inlineBlock" marginRight="sp16" data-testid="price-info">
        <FormattedMessage
          id="price-info.error"
          defaultMessage="Unable to fetch prices"
          description="Label for fetch prices error"
        />
      </Box>
    );
  }
  return (
    <Box display="inlineBlock" marginRight="sp16" data-testid="price-info">
      {price}
    </Box>
  );
};
