import React from "react";
import { H3 } from "@eriksdigital/atomic-ui/components";

import { P, SectionWrapper } from "./styles";
import {FormattedMessage} from "react-intl";

const TitleSection = () => {
  return (
    <SectionWrapper data-testid="title-section">
      <H3>
        <FormattedMessage
            id="hydrofil.heading"
            defaultMessage="Estimated delivery date"
            description="Label of heading title"
        />
      </H3>
      <P>
        <FormattedMessage
            id="hydrofil.description-0"
            defaultMessage="Quickly find a replacement filter element via this selector. ERIKS has a very wide range of filter elements so you can order all filter elements from one supplier. This search engine allows you to select good quality alternatives for the original filter elements."
            description="Label of heading description"
        />
      </P>
      <P>
        <FormattedMessage
            id="hydrofil.description-1"
            defaultMessage="You can search on original brand and code, but it is also possible to enter a (part of a) code. You can then make your selection from the list."
            description="Label of heading description second part"
        />
      </P>
    </SectionWrapper>
  );
};

export default TitleSection;
