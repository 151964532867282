import {FormattedMessage} from "react-intl";
import React from "react";

export const getErrorLabelMessage = (keyLabel : string) => {
    //@ts-ignore
    return {
        "error.default": (
            <FormattedMessage
                id="error.default"
                defaultMessage="Something went wrong, please refresh the page"
                description="Label for error default"
            />),
        "price-info.error":  (
            <FormattedMessage
                id="price-info.error"
                defaultMessage="Unable to fetch prices"
                description="Label for price info error"
            />),
    }[keyLabel];
};