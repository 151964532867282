import React, { useEffect, useState } from "react";
import { PriceInformation } from "../PriceInformation";
import AddToCart from "../AddToCart";
import { PriceWrapper } from "./styles";
import { Link } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";
import { useRaffaella } from "@eriksdigital/raffaella";
import { PriceType } from "../../types/index";

const Price = ({
  erpPartNumber,
  partNumber,
  englishDescription,
  brand,
  itemLink,
  currency,
  position,
  productId,
  stockStatus,
  price,
  priceRaw,
}: PriceType) => {
  const [priceData] = useRaffaella("price");
  const [user] = useRaffaella("user");
  const [itemPrices, setItemPrices] = useState<any>(null);

  useEffect(() => {
    if (!priceData.isLoading && (priceData.data || priceData.error)) {
      setItemPrices(priceData);
    }
  }, [priceData]);

  return user?.data?.loggedIn && !user?.data?.isGuestUser ? (
    <PriceWrapper display="flex" alignItems="center">
      <PriceInformation price={price} priceDetails={itemPrices} />
      <AddToCart
        partNumber={partNumber}
        brand={brand}
        priceRaw={priceRaw}
        englishDescription={englishDescription}
        erpPartNumber={erpPartNumber}
        currency={currency}
        position={position}
        itemLink={itemLink}
        addToCartLoading={itemPrices?.isLoading}
        productId={productId}
        stockStatus={stockStatus}
      />
    </PriceWrapper>
  ) : (
    <Link
      data-testid="loginForPrices"
      alt="Please login to see prices"
      href={user?.data?.loginUrl}
    >
      <FormattedMessage
        id="price-info.login"
        defaultMessage="Please login to see prices"
        description="Label for price info login"
      />
    </Link>
  );
};

export { Price };
