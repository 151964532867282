import React from "react";
import styled from "styled-components";
import {getErrorLabelMessage} from "../../labels/errorLabels";

export interface ErrorProps {
  labelKey?: string;
  before?: string;
  after?: string;
  style?: React.CSSProperties;
}

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
`;

const Error = ({ labelKey = "error.default", before, after, ...rest }: ErrorProps) => {
  return (
    <ErrorWrapper {...rest}>
      {before} {getErrorLabelMessage(labelKey)}
      {". "}
      {after}
    </ErrorWrapper>
  );
};

export default Error;
