import styled from "styled-components";
import { Image } from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";

export const Result = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.default.gray};
  display: flex;
  padding: 14px;

  span {
    color: ${colors.default.midGray};
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ItemImage = styled(Image)`
  flex: 1;
  height: 120px;
  margin-right: 10px;
  min-width: 120px;
  width: 120px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemDescription = styled.div`
  color: ${colors.default.blueC};
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;

  > p {
    display: inline-block;
    font-weight: 600;
  }
`;
