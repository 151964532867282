import React, { useContext, useState, memo, useEffect } from "react";
import {
  TextField,
  LanguageContext,
  ReactSelect,
} from "@eriksdigital/atomic-ui/components";

import { Loading } from "../../components/Loading";
import Error from "../../components/Error";
import { useRaffaella } from "@eriksdigital/raffaella";
import { Aside, FieldGroup, Button } from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import { OptionType, Attributes, AttributesListType } from "../../types/index";

const Sidebar = memo(({ fetchResults }: any) => {
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const [selectedBrand, setSelectedBrand] = useState<AttributesListType>();
  const [selectedPartNumber, selectPartNumber] = useState("");
  const [brands, setBrands] = useState<Attributes>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const [attributesResource, makeResourceCall] = useRaffaella("attributes");

  useEffect(() => {
    setLoading(attributesResource?.isLoading);
    setError(attributesResource?.error);
    if (attributesResource.data) {
      setBrands(attributesResource.data.manufacturer);
    }
  }, [attributesResource]);

  useEffect(() => {
    makeResourceCall &&
      makeResourceCall({
        apiKey: "attributes",
        reqData: { lang: language, erpSystem: erpSystem.toUpperCase() },
      });
  }, [makeResourceCall, language, erpSystem]);

  const intl = useIntl();
  const handleSearch = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    fetchResults({
      manufacturer: selectedBrand?.value || "",
      manufacturerPartNumber: selectedPartNumber,
    });
  };

  const handleSelectBrand = (brand: OptionType): void => {
    setSelectedBrand(brand);
  };

  const handleChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    selectPartNumber(value);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Error
        data-testid="sidebar-error"
        after={intl.formatMessage({
          id: "no-brands.could-not-load",
          defaultMessage: "Brands could not be loaded",
          description: "label for error results not loaded.",
        })}
      />
    );
  }

  return (
    <form onSubmit={handleSearch}>
      <Aside data-testid="sidebar">
        <FieldGroup>
          {brands && (
            <ReactSelect
              id="hydrofil-brands"
              aria-label="hydrofil-brands"
              onChange={handleSelectBrand}
              value={selectedBrand}
              label={
                <FormattedMessage
                  id="brand.label"
                  defaultMessage="Search"
                  description="Label for brand selector"
                />
              }
              isClearable={true}
              options={brands}
              renderOptions={(option: OptionType) => ({
                label: option.value.toUpperCase(),
                value: option.value,
              })}
              placeholder={intl.formatMessage({
                id: "brand.placeholder",
                defaultMessage: "Choose a brand",
                description: "label for choose a brand selector",
              })}
            />
          )}

          <TextField
            id="partnumber-input"
            data-testid="partnumber-input"
            label={
              <FormattedMessage
                id="partnumber.label"
                defaultMessage="Manufacturer’s part number"
                description="Label for partnumber field"
              />
            }
            placeholder={intl.formatMessage({
              id: "partnumber.placeholder",
              defaultMessage: "Part number",
              description: "label for part number field",
            })}
            onChange={handleChange}
            value={selectedPartNumber}
            data-hj-whitelist
          />
        </FieldGroup>

        <Button
          id="search-button"
          data-testid="search-button"
          type="submit"
          aria-label="search-button"
        >
          <FormattedMessage
            id="search.label"
            defaultMessage="Search"
            description="Label for search button"
          />
        </Button>
      </Aside>
    </form>
  );
});

export default Sidebar;
