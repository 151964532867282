import React from "react";
import { Icon } from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { LoadingIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { LoadingWrapper } from "./style";

export const Loading = () => (
  <LoadingWrapper data-testid="hyd-loading">
    <Icon as={LoadingIcon} size="xxxl" color={colors.default.blueD} />
  </LoadingWrapper>
);