import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import {
  LanguageContext,
  Button,
  LoginContext,
} from "@eriksdigital/atomic-ui/components";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { useRaffaella } from "@eriksdigital/raffaella";
import Sidebar from "../../components/Sidebar";
import TitleSection from "../../components/TitleSection";
import Results from "../../components/Results";
import { Main, BottomActions } from "./styles";
import { languageToCode } from "../../utils/languageUtils";
import { FormattedMessage } from "react-intl";
import { SalesOrganization } from "../../types";

const Hydrofil = () => {
  const {
    sendNavigationEvent,
    sendProcessStartEvent,
    sendProcessFinishEvent,
    sendPageViewEvent,
    isReadyToSendEvents,
    //@ts-ignore
  } = useContext<any>(GTMContext);
  const { language, erpSystem } = useContext<any>(LanguageContext);
  const [itemsList, setItemsList] = useState<any>();
  const [itemsStockData, setItemsStockData] = useState<any>();
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);

  const {
    userType,
    userId,
    userSegment,
    loggedIn,
    loginLoading,
    customerNumber,
    organizationName,
    userSalesChannel,
  } = useContext<any>(LoginContext);
  const [user] = useRaffaella("user");
  const [items, makeResourceCall] = useRaffaella("items");
  const [stockLevels, makeResourceCallStockLevels] = useRaffaella(
    "stockLevels"
  );
  const [salesOrgData] = useRaffaella("salesOrganization");
  const [manufacturerDetails, setManufacturerDetails] = useState({
    manufacturer: "",
    manufacturerPartNumber: "",
  });

  useEffect(() => {
    makeResourceCall &&
      makeResourceCall({
        apiKey: "items",
        reqData: {
          lang: language,
          erpSystem: erpSystem.toUpperCase(),
          limit: limit,
          manufacturer: manufacturerDetails.manufacturer,
          manufacturerPartNumber: manufacturerDetails.manufacturerPartNumber,
          sort: "partNumber",
          sortDirection: "ASC",
        },
      });
  }, [makeResourceCall, erpSystem, language, limit, manufacturerDetails]);

  useEffect(() => {
    if (makeResourceCallStockLevels && itemsList) {
      makeResourceCallStockLevels({
        apiKey: "stockLevels",
        reqData: {
          erpSystem: erpSystem.toUpperCase(),
        },
        reqBody: {
          items: itemsList.map((item: any) => {
            return { itemNumber: item.erpPartNumber, unitCode: "PCE" };
          }),
        },
      });
    }
  }, [erpSystem, makeResourceCallStockLevels, itemsList]);

  useEffect(() => {
    setError(items?.error);
    if (items.data) {
      setItemsList(items.data.items);
      setHasMore(items.data.hasMore);
    }
  }, [items]);

  useEffect(() => {
    if (stockLevels && stockLevels.data) {
      setItemsStockData(stockLevels.data);
    }
  }, [stockLevels]);

  useEffect(() => {
    if (isReadyToSendEvents && sendPageViewEvent && loginLoading === false) {
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: customerNumber || "",
        organizationName: organizationName || "",
        userSalesChannel,
        userType,
        userId,
        userSegment,
        loggedIn,
      });
    }
  }, [
    isReadyToSendEvents,
    sendPageViewEvent,
    erpSystem,
    loginLoading,
    loggedIn,
    userType,
    userId,
    userSegment,
    customerNumber,
    organizationName,
    userSalesChannel,
  ]);

  useEffect(() => {
    if (isReadyToSendEvents && sendProcessStartEvent) {
      sendProcessStartEvent({ processStepName: "1_chooseproductdetails" });
    }
  }, [isReadyToSendEvents, sendProcessStartEvent]);

  useEffect(() => {
    if (
      makeResourceCall &&
      itemsList &&
      !user?.isLoading &&
      user?.data?.loggedIn &&
      salesOrgData?.data
    ) {
      const currentStore: SalesOrganization = salesOrgData.data.find(
        (org: SalesOrganization) => org.country === erpSystem.toUpperCase()
      );
      makeResourceCall({
        apiKey: "price",
        reqData: {
          currency: "EUR",
          customerNumber: customerNumber || user.data.customerNumber,
          languageId: languageToCode(language),
          shopId: currentStore.store_id % 10,
        },
        reqBody: {
          items: itemsList.map((item: any) => {
            return { itemNumber: item.erpPartNumber, unitCode: "PCE" };
          }),
        },
      });
    }
  }, [makeResourceCall, erpSystem, language, itemsList, user]);

  const fetchResults = useCallback(
    ({ manufacturer, manufacturerPartNumber }: any) => {
      setManufacturerDetails({ manufacturer, manufacturerPartNumber });

      if (isReadyToSendEvents && sendProcessFinishEvent) {
        sendProcessFinishEvent({ processStepName: "2_showresult" });
      }
    },
    [isReadyToSendEvents, sendProcessFinishEvent]
  );

  const showMore = () => {
    setLimit(limit + 10);

    sendNavigationEvent();
  };

  return (
    <Main>
      <Sidebar fetchResults={fetchResults} />

      <section>
        <TitleSection />

        <Fragment>
          <Results
            loading={items.isLoading}
            error={error}
            products={itemsList}
            stockdata={itemsStockData}
          />

          {hasMore ? (
            <BottomActions>
              <Button
                id="show-more"
                onClick={showMore}
                isLoading={items.isLoading}
                disabled={items.isLoading}
                data-testid="showMoreButton"
              >
                <FormattedMessage
                  id="results.moreResults"
                  defaultMessage="Show more results"
                  description="Label for more results button"
                />
              </Button>
            </BottomActions>
          ) : null}
        </Fragment>
      </section>
    </Main>
  );
};

export default Hydrofil;
