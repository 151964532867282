import styled from "styled-components";
import { colors } from "@eriksdigital/atomic-ui/styles";

export const Wrapper = styled.div`
  border-top: 1px solid ${colors.default.gray};
  margin-top: 40px;
  padding: 10px;
  padding-left: 0;

  h3 + p {
    margin-bottom: 16px;
  }
`;