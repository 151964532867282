import { ResourcesMap } from "@eriksdigital/raffaella";

export type AvailableLanguages = {
  countryCode: string;
};

const config: ResourcesMap = {
  salesOrganization: {
    apiEndpoint: process.env.OPEN_FAAS_URL
      ? `${process.env.OPEN_FAAS_URL}/sales-organizations`
      : "https://fa-ed-eu-tst-gb-cicd.azurewebsites.net/api/sales-organizations",
    method: "GET",
  },
  items: {
    apiEndpoint: process.env.FILTER_ELEMENT_SELECTOR_API
      ? `${process.env.FILTER_ELEMENT_SELECTOR_API}/items`
      : "https://api.test.eriksdigital.com/gs-hydrofil-selector/api/items",
    method: "GET",
  },
  attributes: {
    apiEndpoint: process.env.FILTER_ELEMENT_SELECTOR_API
      ? `${process.env.FILTER_ELEMENT_SELECTOR_API}/attributes`
      : "https://api.test.eriksdigital.com/gs-hydrofil-selector/api/attributes",
    method: "GET",
  },
  stockLevels: {
    apiEndpoint: process.env.ERPAL_INTEGRATION_API
      ? `${process.env.ERPAL_INTEGRATION_API}/v3/stock`
      : "https://api.test.eriksdigital.com/guided-selling-erpal-integration/api/v3/stock",
    method: "POST",
    transformDTO: (data) => data.items,
  },
  user: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cookies/isLoggedIn`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cookies/isLoggedIn",
    method: "GET",
  },
  addToCart: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cart/addToCart`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cart/addToCart",
    method: "POST",
  },
  shoppingCart: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cart`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cart",
    method: "GET",
  },
  availableLanguages: {
    apiEndpoint: ":storeUrl/wcs/resources/store/:storeId/available-languages",
    method: "GET",
    transformDTO: (DTO) => ({ data: Object.keys(DTO) }),
  },
  price: {
    apiEndpoint: process.env.ERPAL_INTEGRATION_API
      ? `${process.env.ERPAL_INTEGRATION_API}/v3/prices`
      : "https://api.test.eriksdigital.com/guided-selling-erpal-integration/api/v3/prices",
    method: "POST",
    transformDTO: (DTO) => ({ data: DTO.items }),
  },
};

export { config };
