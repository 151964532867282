type LanguageCodes = {
    nl: number;
    en: number;
    fr: number;
    de: number;
  };
  const LANGUAGE_CODES: LanguageCodes = {
    nl: 31,
    en: -1,
    fr: -2,
    de: -3,
  };
  
  const languageToCode = (locale: keyof LanguageCodes) => LANGUAGE_CODES[locale];
  
  export { languageToCode };
  