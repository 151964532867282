import React, { useContext, useEffect, useCallback, useState } from "react";
import {
  AddToCart as A2C,
  LanguageContext,
  NotificationContext,
} from "@eriksdigital/atomic-ui/components";
import {CartWrapper} from './styles';
import { languageToCode } from "../../utils/languageUtils";
import { GTMContext } from '@eriksdigital/gs-ga4';
import {FormattedMessage} from "react-intl";
import { useRaffaella } from "@eriksdigital/raffaella";
import { AddToCartProps } from "../../types/index";

const AddToCart = ({
  brand,
  priceRaw,
  englishDescription,
  partNumber,
  erpPartNumber,
  productId,
  position,
  itemLink,
  stockStatus,
  currency,
  addToCartLoading
}: AddToCartProps ) => {
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const { createNotification } = useContext<any>(NotificationContext);
  //@ts-ignore
  const { sendAddToCartEvent } = useContext<any>(GTMContext)
  const [addToCartData, makeResourceCallAddToCart] = useRaffaella('addToCart');
  const [itemQuantity, setItemQuantity] = useState(1);
  const [isData, setIsData] = useState('');

  const handleAddToCartSuccess = useCallback(() => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${erpPartNumber}`,
        variant: "success",
        message: (
          <FormattedMessage
            id="add-to-cart.success"
            defaultMessage="The product was added to your shopping cart"
            description="Item is succesfully added to the shopping cart"
          />
        ),
        autoDismiss: true,
      }, 3000);
  }, [erpPartNumber, createNotification]);

  const handleAddToCartError = useCallback(() => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${erpPartNumber}`,
        variant: "error",
        message: (
          <FormattedMessage
            id="add-to-cart.error"
            defaultMessage="Our apologies! Something went wrong when adding the product to your shopping cart. Please contact the ERIKS support team."
            description="Item was not added to the shopping cart"
          />
        ),
        autoDismiss: true,
      }, 3000);
  }, [ createNotification, erpPartNumber]);

  const handleNotification = useCallback(() => {
    if (!addToCartData.isLoading && !!addToCartData.data && !addToCartData.error) {
      handleAddToCartSuccess();
      setIsData('');
    }
    else if (!addToCartData.isLoading && !addToCartData.data && addToCartData.error) {
      handleAddToCartError();
      setIsData('');
    }
  },[addToCartData, handleAddToCartSuccess, handleAddToCartError ])

  useEffect(() => {
    if (!addToCartData.isLoading && !!addToCartData.data && !addToCartData.error) {
      sendAddToCartEvent &&
        sendAddToCartEvent({
          itemName: englishDescription,
          itemId: partNumber,
          price: priceRaw,
          itemBrand: brand,
          itemStockStatus: stockStatus,
          index: position,
          materialNumber: erpPartNumber,
          quantity: itemQuantity,
        });
    }
  }, [
    englishDescription,
    partNumber,
    priceRaw,
    brand,
    stockStatus,
    erpPartNumber,
    position,
    itemQuantity,
    addToCartData.isLoading,
    addToCartData.error,
    addToCartData.data,
    sendAddToCartEvent,
  ]);

  const handleAddToCart = async (quantity : number, id: string) => {
    setItemQuantity(quantity);
    if (makeResourceCallAddToCart) {
      await makeResourceCallAddToCart({
        apiKey: "addToCart",
        reqData: {
          locale: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          erpPartNo: erpPartNumber,
          quantity,
          targetSystem: "SEL_HYD",
        },
      });
      setIsData(id);
    }
  };

  useEffect(() => {
    if(isData === erpPartNumber) {
      handleNotification();
    }
  },[isData, erpPartNumber, handleNotification] )

  return (
    <CartWrapper>
      <A2C
        table
        id={partNumber}
        data-testid={partNumber}
        onAdd={(quantity: number) => handleAddToCart(quantity, erpPartNumber)}
        quantityInitialValue={1}
      />
    </CartWrapper>
  );
};

export default AddToCart;
