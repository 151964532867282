import React, { useContext, SyntheticEvent, ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import {
  Toast,
  NotificationContext,
} from "@eriksdigital/atomic-ui/components";

export interface Notification {
  id: string;
  variant?: "success" | "warning" | "info" | "error";
  message?: string | ReactNode;
  autoDismiss?: boolean;
  link?: string;
}

export interface NotificationContextInterface {
  notifications?: Notification[];
  dismissNotification?: Function;
  createNotification?: Function;
}

export const NotificationCenter = () => {
  const { notifications, dismissNotification } = useContext<any>(
    NotificationContext
  );

  return ReactDOM.createPortal(
    <ToastWrapper>
      <Notifications
        notifications={notifications}
        dismissNotification={dismissNotification}
      />
    </ToastWrapper>,
    document.querySelector("#notifications") || document.createElement("div")
  );
};

export const Notifications = ({
  notifications,
  dismissNotification,
}: NotificationContextInterface) => {
  const handleDismissNotification = (e: SyntheticEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;

    dismissNotification && dismissNotification(target.id.split("_")[1]);
  };

  const onClickNotification = (
    e: SyntheticEvent,
    notification: Notification
  ) => {
    handleDismissNotification(e);
    if (notification.link) {
      window.open(notification.link, "_blank");
    }
  };

  return (
    <>
      {notifications &&
        notifications.map((notification: Notification) => {
          return (
            <Toast
              id={notification.id}
              key={notification.id}
              alert={notification.variant}
              onDismiss={handleDismissNotification}
              onClick={(e: SyntheticEvent) =>
                onClickNotification(e, notification)
              }
            >
              {notification.message}
            </Toast>
          );
        })}
    </>
  );
};

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 32px;
  right: 32px;
  width: auto;
  align-items: stretch;
  z-index: 2;
`;
