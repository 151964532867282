import React from "react";
import { H3, H4} from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";
import { Wrapper } from "./style";

const NoResults = () => {
  return (
    <Wrapper data-testid="no-results">
      <H3>
        <FormattedMessage
          id="no-results.heading"
          defaultMessage="No search results"
          description="label for no results heading"
        />
      </H3>
      <p>
        <FormattedMessage
          id="no-results.description"
          defaultMessage="No search results"
          description="label for no results description"
        />
      </p>
      <H4>
        <FormattedMessage
          id="no-results.suggestion-heading"
          defaultMessage="Try one of the following suggestions for better results:"
          description="label for no results suggestion heading"
        />
      </H4>
      <ul>
        <li>
          <FormattedMessage
            id="no-results.suggestion-1"
            defaultMessage="Check the spelling of the search term."
            description="label for no results suggestion"
          />
        </li>
      </ul>
    </Wrapper>
  );
};

export default NoResults;