import { Language, languageToCode } from "./languageUtils";

export type EnvironmentsType = "dev" | "test" | "acc" | "prod";

const environments : { [key in EnvironmentsType]: string } = {
  dev: "dev.",
  test: "dev.",
  acc: "test.",
  prod: "",
};

type ShopNames = "nl" | "be" | "lu" | "fr" | "ch" | "uk" | "de";

const shopNames: { [key in ShopNames]: string } = {
  nl: "shop.eriks.nl/",
  be: "shop.eriks.be/",
  lu: "shop.eriks.lu/",
  fr: "shop.eriks.fr/",
  ch: "shop.maagtechnic.ch/",
  uk: "shop.eriks.co.uk/",
  de: "shop.eriks.de/"
};

export const constructUrl = (
  environment: EnvironmentsType = "test",
  erpSystem: ShopNames = "nl",
  language: string = "nl"
) => {
  return `https://${environments[environment]}${shopNames[erpSystem]}${language}`;
};

export const constructShoppingUrl = (
  data: any,
  language: Language,
  erpSystem: ShopNames
) => {
  if (data === null || data.getSalesOrganizations === null) {
    return "/";
  }
  const salesOrganization: any  = 
    data.find((org: any) => org.country === erpSystem.toUpperCase());
  if (!salesOrganization) {
    return "/";
  }
  return `${
    salesOrganization.shop_url
  }ShoppingCartDisplay?catalogId=1000&langId=${languageToCode(
    language
  )}&storeId=${salesOrganization.store_id}`;
};
