import React, { useContext, useEffect, useCallback } from "react";
import {
  Icon, Link,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import { colors } from '@eriksdigital/atomic-ui/styles';
import { LoadingIcon, CartIcon, } from '@eriksdigital/atomic-ui/components/Icons';
import { IconWrapper, ItemCountCircle } from "./style";
import { useRaffaella } from "@eriksdigital/raffaella";
import { languageToCode } from "../../utils/languageToCode";
import { useShoppingCartUrl } from "../../hooks/useShoppingCartUrl";

export const ShoppingCart: React.FC = () => {
  const shoppingCartUrl = useShoppingCartUrl();
  const [shoppingCart, makeResourceCall] = useRaffaella("shoppingCart");
  const [addToCart] = useRaffaella("addToCart");
  const { language, erpSystem } = useContext<any>(LanguageContext);

  const { isLoading, data } = shoppingCart;

  const x_totalOrderItemCount = data?.x_totalOrderItemCount ?? 0;

  const fetchShoppingCart = useCallback(() => {
    makeResourceCall && makeResourceCall({
      apiKey: "shoppingCart",
      reqData: {
        locale: languageToCode(language),
        erpSystem: erpSystem.toUpperCase(),
        targetSystem: "SEL_HYD",
      },
    });
  },[erpSystem, language, makeResourceCall]);

  useEffect(() => {
    if (!!addToCart.data && addToCart.data.responseUrl) {
      fetchShoppingCart();
    }
  }, [addToCart.data, fetchShoppingCart]);

  useEffect(() => {
    fetchShoppingCart();
  }, [fetchShoppingCart]);

  return (
    <div id={"navbar-cart-icon"}>
      <Link
        href={shoppingCartUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="navbar-go-to-cart"
      >
        <IconWrapper>
          <ItemCountCircle isLargeAmount={x_totalOrderItemCount > 99}>
            {isLoading ? (
              <Icon as={LoadingIcon} size="sz8" color={colors.default.blueD} />
            ) : (
              x_totalOrderItemCount
            )}
          </ItemCountCircle>
          <span id="cart-itemcount" style={{ display: "none" }}>
            {x_totalOrderItemCount}
          </span>
          <Icon
            as={CartIcon}
            fill={colors.heading.primary}
            height={30}
            width={30}
            viewBox="0 0 35 35"
          />
        </IconWrapper>
      </Link>
    </div>
  );
};
