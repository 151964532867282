import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext, Logo } from "@eriksdigital/atomic-ui/components";

import { StyledSelect } from "../Select/style";

import Hamburger from "../Hamburger";
import { ShoppingCart } from "../ShoppingCart";
import { LogInStatus } from "../LogInStatus";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
  MenuPanelContainer,
  LanguagePanel,
} from "./style";
import { constructUrl, EnvironmentsType } from "../../utils/shopUtils";
import { LocalesDropdownItem,
  pimLocalesDropdown,
  LocalesDropdown, } from "../../utils/languageUtils";
import { ENVIRONMENT } from "../../constants";
import { useRaffaella } from "@eriksdigital/raffaella";
import { languageToCode } from "../../utils/languageToCode";

export type SalesOrganization = {
  sales_organization: string;
  shop_url: string;
  country: string;
  store_id: number;
  guided_selling_base_url: string;
};

const Header = () => {
  const { language, setLanguage, erpSystem } = useContext<any>(LanguageContext);
  const [isMenuOpen, toggleMenu] = useState(false);
  const menu = useRef<HTMLDivElement>(null);
  const [languageDropdown, updateLanguageDropdown] = useState<LocalesDropdownItem[]>
    (Object.values(pimLocalesDropdown)
  );
  
  const [salesOrganization, makeResourceCallSalesOrg] = useRaffaella("salesOrganization");
  const [availableLanguages] = useRaffaella("availableLanguages");
  const [user, makeResourceCallUser] = useRaffaella("user");

  useEffect(() => {
    makeResourceCallSalesOrg && makeResourceCallSalesOrg({ apiKey: "salesOrganization" });
  }, [makeResourceCallSalesOrg]);


  useEffect(() => {
    makeResourceCallUser && makeResourceCallUser({
      apiKey: "user",
      reqData: {
        languageId: languageToCode(language),
        erpSystem: erpSystem.toUpperCase(),
        targetSystem: "SEL_HYD"
      }
    });
  }, [makeResourceCallUser, language, erpSystem]);

  useEffect(() => {
    if (salesOrganization.data) {
      const currentStore: SalesOrganization = salesOrganization.data.find(
        (org: SalesOrganization) => org.country === erpSystem.toUpperCase()
      );
      makeResourceCallSalesOrg &&
        makeResourceCallSalesOrg({
          apiKey: "availableLanguages",
          reqData: {
            storeUrl: currentStore.shop_url.slice(0, -1),
            storeId: currentStore.store_id,
          },
        });
    }
  }, [salesOrganization, makeResourceCallSalesOrg, erpSystem]);

  useEffect(() => {
    if (availableLanguages.data) {
      const newDropdown = availableLanguages.data.map(
        (lang: { countryCode: "string" }) => 
          pimLocalesDropdown[lang.countryCode]
      );
      updateLanguageDropdown(newDropdown);
      if (!newDropdown.find((locale: LocalesDropdown) => locale.value === language)) {
        setLanguage(newDropdown[0].value);
      }
    }
  }, [language, availableLanguages, setLanguage]);

  const handleChangeLanguage = ({ target: { value }, }: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(value);
  };

  const handleMenuToggle = () => {
    toggleMenu(!isMenuOpen);
  };

  const handleClickOutside = (e: MouseEvent): void => {
    if (menu.current && menu.current.contains(e.target as HTMLElement)) {
      return;
    }
    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper
            data-testid="hyd-logo"
            href={`${constructUrl(ENVIRONMENT as EnvironmentsType, erpSystem, language)}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo />
          </LogoWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            {user?.data?.loggedIn && <ShoppingCart />}
            {user?.data && <LogInStatus user={user.data} />}
            <Hamburger
              id="menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
          </div>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map((lang) => {
                return (
                  <option value={lang.value} key={lang.value}>
                    {lang.label}
                  </option>
                );
              })}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
